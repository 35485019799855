export const API_URL = "https://w26q44oywb.execute-api.us-east-1.amazonaws.com/dev"
// export const API_URL = "http://localhost:3000/dev"

export const SortOrder = {
  asc: 'ASC',
  desc: 'DESC'
}

export const CHART_TOOLTIP_MAPPING = {
  totalOrders: "Total Orders",
  noStatus: "Orders with no status",
  holdingExpiredDateStatus: "Orders with HOLDING Expiration date status",
  newStatus: "Orders with NEW status",
  processingStatus: "Orders with PROCESSING status",
  holdingStatus: "Orders with HOLDING status",
  completedStatus: "Orders with COMPLETED status",
  successStatus: "Orders with SUCCESS status",
  cancelStatus: "Orders with CANCELLING/CANCELLED status",
  errorStatus: "Orders with ERROR status",
  expiredStatus: "Orders with EXPIRED status",
};

export const ORDER_STATUS = {
  new: 'NEW',
  processing: 'PROCESSING',
  holding: 'ON_HOLD',
  holdingExpDate: 'EXPIRATION_DATE_PAST',
  success: 'SUCCESS',
  error: 'ERROR',
  terminalAccepted: 'TERMINAL_ACCEPTED',
  terminalError: 'TERMINAL_ERROR',
  terminalApiRejected: 'TERMINAL_API_REJECTED',
  cancelling: 'CANCELLING',
  cancelled: 'CANCELLED',
  tandemError: 'TANDEM_ERROR',
  tandemAlert: 'TANDEM_ALERT',
  crossRefError: 'CROSS_REF_ERROR',
  expired: 'EXPIRED',
  completed: 'COMPLETED',
  updateXRefError: 'UPDATE_XREF_ERROR'
}

export const MAX_FILE_UPLOAD_SIZE = 30 * 1024 * 1024;
